@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  white-space: pre-line;
}

@font-face {
  font-family: "NanumSquare";
  src: url("./assets/fonts/NanumSquare/NanumSquare.woff") format("woff2"),
    url("./assets/fonts/NanumSquare/NanumSquare.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NanumSquareEB";
  src: url("./assets/fonts/NanumSquare/NanumSquareEB.woff") format("woff2"),
    url("./assets/fonts/NanumSquare/NanumSquareEB.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NanumSquareL";
  src: url("./assets/fonts/NanumSquare/NanumSquare_L.woff") format("woff2"),
    url("./assets/fonts/NanumSquare/NanumSquare_L.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NanumSquareR";
  src: url("./assets/fonts/NanumSquare/NanumSquare_R.woff") format("woff2"),
    url("./assets/fonts/NanumSquare/NanumSquare_R.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* 
@font-face {
  font-family: 'Open Sans B';
  src: url('./assets/fonts/OpenSans-Bold.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans L';
  src: url('./assets/fonts/OpenSans-Light.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans EB';
  src: url('./assets/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans M';
  src: url('./assets/fonts/OpenSans-Medium.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans R';
  src: url('./assets/fonts/OpenSans-Regular.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SB';
  src: url('./assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

.czoom {
  zoom: 75%;
}

html {
  @apply font-regular;
  -webkit-tap-highlight-color: transparent;
}

* {
  /* font-family: @apply font-regular; */
  /* font-family: 'Open Sans', sans-serif; */
}
